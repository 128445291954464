import * as XLSX from 'xlsx';
import JSZip from "jszip";
import { saveAs } from 'file-saver';

export const exportHalperinTransfersFeesFile = (jsonData, showToast) => {


    const keyMapping = {
        "employeeName": "שם עובד",
        "totalAmount": "סה\"כ מקדמה",
        "totalTransferFee": "סה\"כ עמלה",
        "employeeInternalId": "מספר עובד"
    };

    // Transform JSON data to use Hebrew keys
    const transformedData = jsonData.map(item => {
        return Object.keys(item).reduce((newItem, key) => {
            if (keyMapping[key]) { // Check if the key exists in the keyMapping
                newItem[keyMapping[key]] = item[key];
            }
            return newItem;
        }, {});
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TransfersListWithFee');

    // Define filename for the Excel file
    const fileName = 'transfers_list_with_fee.xlsx';

    // Write and download the file
    XLSX.writeFile(workbook, fileName);
    showToast({
        position: "top",
        title: 'הורדת המסמך הצליחה',
        status: 'success',
        duration: 5000,
        isClosable: true,
    });
};

export const exportElectraTransfersFeesFile = (jsonData, showToast) => {

    const keyMapping = {
        "employeeName": "שם עובד",
        "totalAmount": "סה\"כ מקדמה",
        "totalTransferFee": "סה\"כ עמלה",
        "employeeInternalId": "מספר עובד"
    };

    // Transform JSON data to use Hebrew keys
    const transformedData = jsonData.map(item => {
        return Object.keys(item).reduce((newItem, key) => {
            if (keyMapping[key]) { // Check if the key exists in the keyMapping
                newItem[keyMapping[key]] = item[key];
            }
            return newItem;
        }, {});
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TransfersListWithFee');

    // Define filename for the Excel file
    const fileName = "Payroll-Detailed.xlsx";

    // Write and download the file
    XLSX.writeFile(workbook, fileName);
    showToast({
        position: "top",
        title: 'הורדת המסמך הצליחה',
        status: 'success',
        duration: 5000,
        isClosable: true,
    });
};

export const exportFormReportPdfZipFile = (jsonData) => {
    const zip = new JSZip();
    const pdfs = jsonData.map((item) => {
        return {
            name: `${item.userFullName}_${item.formId}.pdf`,
            content: item.signedContent,
        };
    });

    pdfs.forEach((pdf) => {
        zip.file(pdf.name, pdf.content, {base64: true});
    });

    zip.generateAsync({type: "blob"})
        .then((content) => {
            saveAs(content, "FormReport.zip");
        });
}

export const exportFormsReportFile = (data) => {
    try {
        // Convert JSON data to the desired format with translated column names
        const finalArray = data.map((item) => ({
            "שם עובד": item.userFullName,
            "מספר טופס": item.formId,
            "תאריך מדויק": item.exactDate,
            "מזהה מכשיר": item.userAgent,
            "כתובת IP": item.ip,
        }));

        // Create worksheet and workbook
        const newWorksheet = XLSX.utils.json_to_sheet(finalArray);
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");

        // Fix headers
        XLSX.utils.sheet_add_aoa(newWorksheet, [["שם עובד", "מספר טופס", "תאריך מדויק", "מזהה מכשיר", "כתובת IP"]], {origin: "A1"});

        // Write the workbook to a file
        XLSX.writeFile(newWorkbook, "FormReport.xlsx");
    } catch (e) {
        console.error("Error exporting Excel file:", e);
    }
};

export const exportHomeCenterTransfersFeesFile = (jsonData, showToast) => {

    const keyMapping = {
        "employeeInternalId": "מספר עובד",
        "govId": "תעודת זהות",
        "employeeFirstName": "שם עובד",
        "employeeLastName": "שם משפחה",
        "totalAmount": "גובה מקדמה לניכוי",
        "totalTransferFee": "סה\"כ עמלה לניכוי",
        "branch": "סניף",
    };

    // Transform JSON data to use Hebrew keys
    const transformedData = jsonData.map(item => {
        return Object.keys(item).reduce((newItem, key) => {
            if (keyMapping[key]) { // Check if the key exists in the keyMapping
                newItem[keyMapping[key]] = item[key];
            }
            return newItem;
        }, {});
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TransfersListWithFee');

    // Define filename for the Excel file
    const fileName = 'transfers_list_with_fees.xlsx';

    // Write and download the file
    XLSX.writeFile(workbook, fileName);
    showToast({
        position: "top",
        title: 'הורדת המסמך הצליחה',
        status: 'success',
        duration: 5000,
        isClosable: true,
    });
};
